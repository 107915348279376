@import '/src/styles/index.scss';

@mixin exk-widget-visual-receipt-shadow {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 28px;
    top: -28px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.container {
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  background: _var(--body-background-color);
  font-family: _var(--body-font-family);
}

.header {
  padding: 0 0 _var(--padding-4);

  .container--small & {
    padding: 0;
  }
}

.list {
  display: block;
  list-style: none;
  font-family: _var(--body-font-family);

  // No header
  &:first-child {
    padding-top: _var(--padding-5);
  }
}

.item {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 0 _var(--padding-5);

  .container--small & {
    padding-left: 0;
    padding-right: 0;
  }

  &:first-of-type {
    padding-top: _var(--padding-5);
  }

  > *:first-child {
    max-width: 66.667%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--separated {
    padding-bottom: _var(--list-item-vertical-padding, rem-calc(25));
    margin-bottom: _var(--list-item-vertical-padding, rem-calc(25));
    border-bottom: 1px solid _var(--list-divider-color, $gray-20);
  }

  &--sub > *:first-child {
    padding-left: rem-calc(20);
  }

  // Last (Total/Summary) Row
  &--last {
    align-items: center;
    justify-content: space-between;
    padding-bottom: _var(--padding-8);

    > *:first-child {
      flex: 1 0 auto; // Ensure label always visible
      padding-right: _var(--padding-2_5);
    }

    // Ensure amounts are
    // always visible
    > *:last-child {
      flex: 1 1 auto;

      // Move loader to right
      > svg {
        margin-left: auto;
      }
    }

    > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__name {
    flex: 0 1 auto;
    max-width: 70%;
  }

  &__underline {
    flex: 1 0 auto;
    margin: 0 rem-calc(8) rem-calc(4);
    border-bottom: 1px dashed _var(--list-item-divider-color, $gray-30);
  }

  &__value {
    flex: 0 1 auto;
    text-align: right;
  }

  &__icon {
    margin-left: rem-calc(3);
  }
}

.footer {
  padding: 0 0 _var(--padding-5);
  text-align: center;

  // Mobile only styles
  // to hide mobile overview
  &--mobileOverview {
    position: relative;
    z-index: 2;
    background-color: _var(--body-background-color);
    border-top: 1px solid $gray-20;
    padding: _var(--padding-8) 0 _var(--padding-5);
  }

  &__action {
    width: 100%;

    & > span {
      font-weight: $font-weight-normal;
    }
  }

  &__prompt {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: _var(--padding-2_5);
  }

  &__promptImg {
    min-width: rem-calc(32);
    height: rem-calc(32);
    margin-right: rem-calc(12);
    border-radius: 100%;
  }
}

//
// Mobile Sticky Overview
//

.mobileOverview {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  z-index: 1;
  white-space: nowrap;
  padding: _var(--padding-6) _var(--padding-right) _var(--padding-5)
    _var(--padding-left);
  background-color: $white;
  border-top: 1px solid $gray-20;
  cursor: pointer;
  @include exk-widget-visual-receipt-shadow;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      margin-right: auto;
    }
  }

  &__icon {
    flex: 0 1 auto;
    margin-left: _var(--padding-2_5);
  }

  // Appears under footer element
  &--underFooter {
    margin-top: rem-calc(-71);
    padding-right: rem-calc(12);

    // Hide shadow
    &::after {
      display: none;
    }
  }
}

//
// Typography
//

.heading {
  font-size: _var(--font-size-xl);
  line-height: _var(--line-height-xl);
}

.label {
  color: _var(--list-item-color, $gray-60);

  .item--sub & {
    font-size: _var(--small-font-size);
    line-height: _var(--small-line-height);
  }

  &--lastLabel,
  &--lastValue {
    color: _var(--body-font-color);
  }

  &--lastLabel {
    font-size: _var(--font-size-xl);
    line-height: _var(--line-height-xl);
    font-weight: $font-weight-bold;
  }

  &--lastValue {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: _var(--body-font-weight, $font-weight-normal);
  }
}

.overview,
.overviewMeta {
  font-size: _var(--font-size-lg);
  line-height: _var(--line-height-lg);
}

.overview {
  color: _var(--body-font-color);
}

.overviewMeta {
  color: $gray-70;
}

//
// Large Container Layout
//

// Replace full-width appearance
// with restrained bordered container
.container--large {
  .item {
    &--last {
      padding-bottom: _var(--padding-5);
    }
  }
}

//
// Typography
//
.title {
  font-size: _var(--body-font-size);
  font-weight: normal;
}
